interface Props {
  className?: string;
}

export default function DefaultAvatar({className}: Props) {
  return (
    <svg
      className={className ? className : ''}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <rect width="48" height="48" rx="24" fill="#DED7FE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 24.1856C27.9764 24.1856 31.2 20.9621 31.2 16.9856C31.2 13.0092 27.9764 9.78564 24 9.78564C20.0235 9.78564 16.8 13.0092 16.8 16.9856C16.8 20.9621 20.0235 24.1856 24 24.1856ZM24 43.3856C30.0499 43.3856 35.4555 40.5726 38.9774 36.1858C35.4555 31.7989 30.0497 28.9856 23.9997 28.9856C17.9498 28.9856 12.5442 31.7987 9.02234 36.1855C12.5442 40.5724 17.9499 43.3856 24 43.3856Z"
        fill="#4D1D95"
      />
    </svg>
  );
}
