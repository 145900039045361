import {useState, useCallback, useRef} from 'react';
import type {FormEvent, ChangeEvent} from 'react';
import {useFetcher} from '@remix-run/react';
import debounce from 'lodash/debounce';

export interface ServicesResults {
  serviceHandle: string;
  label: string;
  description: string;
  parentHandle: string;
}

export interface PartnersResults {
  partnerHandle: string;
  name: string;
  bio: string;
  avatarUrl: string;
}

export interface CountriesResults {
  countryCode: string;
  group: string;
  countryHandle: string;
  name: string;
  partnerCount: number;
}

const SEARCH_API_ENDPOINT = '/partners/directory/api/search';

export const useSearchFormHelpers = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    data = {},
    submit,
    Form,
  }: {data: any; submit: any; Form: any} = useFetcher();
  const [showFlyout, setShowFlyout] = useState(false);

  const {
    searchTerm,
    partners,
    services,
    countries,
    errors,
  }: {
    searchTerm: string;
    partners: PartnersResults[];
    services: ServicesResults[];
    countries: CountriesResults[];
    errors: string[];
  } = data;

  const dismissSearchResults = useCallback(() => {
    submit(
      {'directory-search': ''},
      {
        method: 'POST',
        action: SEARCH_API_ENDPOINT,
      },
    );
    formRef.current?.reset();
    setShowFlyout(false);
  }, [submit]);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const searchValue = (
        formRef.current?.querySelector('input[type="text"]') as HTMLInputElement
      ).value;

      submit(
        {
          'directory-search': searchValue || '',
        },
        {
          method: 'POST',
          action: SEARCH_API_ENDPOINT,
        },
      );

      setShowFlyout(true);
    },
    [submit],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((searchValue: string) => {
      submit(
        {
          'directory-search': searchValue || '',
        },
        {
          method: 'POST',
          action: SEARCH_API_ENDPOINT,
        },
      );
    }, 300),
    [submit],
  );

  const onChange = (event: ChangeEvent<HTMLFormElement>) => {
    const searchValue = event.target.value;

    setShowFlyout(!!searchValue);

    handleChange(searchValue);
  };

  return {
    formRef,
    Form,
    showFlyout,
    searchTerm,
    partners,
    services,
    countries,
    dismissSearchResults,
    errors,
    onChange,
    onSubmit,
  };
};
