import {cva} from 'class-variance-authority';
import {useRef, useState, useEffect} from 'react';

import {FOCUSABLE_TAGS, useFocusTrap} from '@/hooks/useFocusTrap';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {useScrollDirection} from '@/hooks/useScrollDirection';
import type {PartnersDirectoryUser} from '@/pages/shopify.com/($locale)/partners/directory/types';

import DefaultAvatar from './components/DefaultAvatar';
import LogoutIcon from './components/LogoutIcon';

const styles = cva(
  'absolute top-0 mt-[4rem] pb-3 rounded-lg transition-all duration-500 bg-white text-black shadow-light',
  {
    variants: {
      isHidden: {
        true: 'sr-only opacity-0 -translate-y-6',
        false: 'opacity-100 translate-y-0',
      },
    },
  },
);

interface Props {
  user?: PartnersDirectoryUser;
}

export default function DesktopUserMenu({user}: Props) {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const scrollDirection = useScrollDirection();

  const [currentUrl, setCurrentUrl] = useState(
    'https://shopify.com/partners/directory',
  );
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (scrollDirection === 'down' && !isHidden) {
      setIsHidden(true);
    }
  }, [scrollDirection, isHidden]);

  const ref = useRef<HTMLDivElement>(null);

  useFocusTrap(ref, !isHidden && !activeIndex, FOCUSABLE_TAGS);

  useOnClickOutside(() => setIsHidden(true));
  useKeyPress('Escape', () => {
    if (activeIndex === null) {
      setIsHidden(true);
    } else {
      setActiveIndex(null);
    }
  });
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  return (
    <nav data-click-outside="dismiss">
      <div className="overflow-hidden flex items-center">
        <button
          type="button"
          aria-expanded={!isHidden}
          aria-controls="id_partners_user_menu"
          className="text-left outline-4 -outline-offset-4 outline-state-focus focus-visible:outline"
          onClick={() => setIsHidden(!isHidden)}
        >
          <div className="flex items-center gap-x-4">
            {user?.avatar ? (
              <div className="w-12 h-12 rounded-full overflow-hidden">
                <img
                  src={user.avatar}
                  alt={user.storeName}
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <DefaultAvatar />
            )}

            <div className="flex flex-col w-full truncate">
              <span className="text-body-sm font-semibold">
                {user?.storeName}
              </span>
              <span className="text-body-sm text-eco-fg-secondary">
                {user?.email}
              </span>
            </div>
          </div>
        </button>
      </div>
      <div ref={ref} id="id_partners_user_menu" className={styles({isHidden})}>
        <ul className="flex flex-col">
          <li className="flex items-center gap-x-4 py-3 px-6">
            <LogoutIcon />
            <a href={`/services/identity/logout?return_to=${currentUrl}`}>
              <span className="text-body-sm">
                {t('header.userMenu.logout')}
              </span>
            </a>
          </li>
          <li className="border-t py-3 px-6">
            <a href="https://help.shopify.com/en/partners/directory">
              <span className="text-body-sm">{t('header.userMenu.help')}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
