import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';

import SearchIcon from './SearchIcon';

export default function BrowseAllServices({
  dismissSearchResults,
}: {
  dismissSearchResults: () => void;
}) {
  const {localizePath} = useTranslations();
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  return (
    <>
      <div className="border-t border-subdued-light mx-2" />

      <a
        href={localizePath('/partners/directory/services')}
        className="flex gap-x-4 hover:bg-slate-100 active:bg-slate-100 focus:bg-slate-100 px-2 py-3 rounded-lg bg-transparent"
        onClick={() => dismissSearchResults()}
      >
        <SearchIcon className="h-5 w-5" />

        <div className="flex flex-col">
          <h3 className="text-t8 flex-auto">
            {t('search.exploreAll.heading')}
          </h3>
          <p className="flex-auto text-sm text-subdued">
            {t('search.exploreAll.subhead')}
          </p>
        </div>
      </a>
    </>
  );
}
