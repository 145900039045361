import {ShoppingBagOnlyLogo} from '@/components/brochureV2/section/Header/svg/ShopifyLogo';
import {useSiteData} from '@/hooks/useSiteData';
import {twMerge} from '@/stylesheets/twMerge';
import {GlobalNavWrapper} from '@/components/base/sections/Header/modules/SecondaryNavigation/components/GlobalNavWrapper';
import {MobileNavigation} from '@/components/base/sections/Header/modules/SecondaryNavigation/components/MobileNavigation';
import Anchor from '@/components/shared/Anchor/Anchor';
import {useSharedTranslations} from '@/hooks/useTranslations';
import type {SecondaryNavigationProps as BaseSecondaryNavigationProps} from '@/components/base/sections/Header/modules/SecondaryNavigation/SecondaryNavigation';
import type {PartnersDirectoryUser} from '@/pages/shopify.com/($locale)/partners/directory/types';

import Search from '../Search/Search';

import {DesktopNavigation} from './components/DesktopNavigation/DesktopNavigation';
import CtaLinks from './components/CtaLinks/CtaLinks';

const styles =
  'flex items-center w-full h-14 sm:h-global-header box-border text-black';

interface SecondaryNavigationProps extends BaseSecondaryNavigationProps {
  user?: PartnersDirectoryUser;
}

export default function SecondaryNavigation({
  className,
  secondaryNav,
  showGlobals = false,
  user,
}: SecondaryNavigationProps) {
  const {canonicalUrl} = useSiteData();
  const path = new URL(
    (canonicalUrl as string) ?? `https://www.shopify.com/partners/directory`,
  ).pathname;
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  if (!secondaryNav?.links) {
    return null;
  }

  return (
    <div className={twMerge(styles, className)}>
      <GlobalNavWrapper showGlobals={showGlobals} className="mr-8">
        <a
          href="/partners/directory"
          data-component-name="logo-home"
          aria-label={t('header.nav.logo')}
        >
          <ShoppingBagOnlyLogo className="w-8 h-9" aria-hidden="true" />
        </a>
      </GlobalNavWrapper>
      <DesktopNavigation links={secondaryNav.links} path={path} />
      <GlobalNavWrapper showGlobals={showGlobals} className="flex-1">
        <Search hideSearchResults={showGlobals} />
      </GlobalNavWrapper>
      <MobileNavigation
        sectionTitle={secondaryNav.sectionTitle}
        sectionOverviewUrl={secondaryNav.sectionOverviewUrl}
        pageTitle={secondaryNav.pageTitle}
        links={secondaryNav.links}
        mode="light"
      />
      <GlobalNavWrapper showGlobals={showGlobals}>
        <ul>
          <li className="text-base">
            <Anchor
              className="whitespace-nowrap hover:underline text-black"
              href="/partners/directory/services"
              componentName="browse-partners"
            >
              {t('header.browse')}
            </Anchor>
          </li>
        </ul>
      </GlobalNavWrapper>
      <GlobalNavWrapper showGlobals={showGlobals}>
        <ul>
          <CtaLinks user={user} />
        </ul>
      </GlobalNavWrapper>
    </div>
  );
}
