export function ListingsErrorBanner({
  errorMessage,
}: {
  errorMessage: React.ReactNode;
}) {
  return (
    <div
      className="flex justify-start items-center py-3 px-4 rounded-md bg-[#FCF0D4] border border-md border-[#E1B878]"
      role="alert"
    >
      <svg
        className="mr-4"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM9 6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6V10C11 10.5523 10.5523 11 10 11C9.44771 11 9 10.5523 9 10V6ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15Z"
          fill="#B98900"
        />
      </svg>

      <h3 className="text-sm">{errorMessage}</h3>
    </div>
  );
}
