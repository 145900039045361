import {cva} from 'class-variance-authority';

export const logoStyles = cva(
  'w-[175px] h-[28px] sm:w-[200px] sm:h-[32px] md:w-[225px] md:h-[36px] transition-all',
  {
    variants: {
      isSearchFocused: {
        true: 'opacity-0 nav:opacity-100 translate-x-6 nav:translate-x-0 pointer-events-none nav:pointer-events-auto',
        false: 'opacity-100 translate-x-0 delay-300',
      },
    },
  },
);

export const searchStyles = cva(
  'nav:flex-auto mx-margin nav:mx-auto absolute nav:static inset-x-0 flex items-center gap-x-4 transition-all',
  {
    variants: {
      isSearchFocused: {
        true: 'opacity-100 translate-x-0 delay-300',
        false:
          'opacity-0 -translate-x-6 pointer-events-none nav:opacity-100 nav:translate-x-0 nav:pointer-events-auto',
      },
    },
  },
);

export const mobileCtaLinkListStyles = cva(
  'nav:hidden flex items-center transition-all',
  {
    variants: {
      hasHamburger: {
        true: 'mr-[-15px]',
        false: '',
      },
      isSearchFocused: {
        true: 'hidden',
        false: 'flex',
      },
    },
  },
);
