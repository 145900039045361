import {cva} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';
import {useRef} from 'react';

import {FOCUSABLE_TAGS, useFocusTrap} from '@/hooks/useFocusTrap';
import {useKeyPress} from '@/hooks/useKeyPress';
import {twMerge} from '@/stylesheets/twMerge';

const styles = cva(
  'absolute top-[80px] duration-500 left-0 rounded-lg bg-white whitespace-nowrap transition-all box-content bg-white text-black shadow-light',
  {
    variants: {
      isActive: {
        true: 'translate-y-0 opacity-100',
        false: 'sr-only opacity-0 -translate-y-6',
      },
      isSubActive: {
        true: 'w-[550px]',
      },
      filler: {
        1: 'pb-12',
        2: 'pb-24',
        3: 'pb-36',
        4: 'pb-48',
        5: 'pb-60',
      },
    },
    compoundVariants: [
      {
        isSubActive: true,
        filler: 1,
        className: 'pb-12',
      },
      {
        isSubActive: true,
        filler: 2,
        className: 'pb-24',
      },
      {
        isSubActive: true,
        filler: 3,
        className: 'pb-36',
      },
      {
        isSubActive: true,
        filler: 4,
        className: 'pb-48',
      },
      {
        isSubActive: true,
        filler: 5,
        className: 'pb-60',
      },
    ],
    defaultVariants: {
      filler: null,
    },
  },
);

interface DropdownWrapperProps {
  id: string;
  closeTier1: () => void;
  filler?: 1 | 2 | 3 | 4 | 5;
  isActive: boolean;
  isSubActive: boolean;
}

export default function DropdownWrapper({
  children,
  closeTier1,
  filler,
  id,
  isActive,
  isSubActive,
}: PropsWithChildren<DropdownWrapperProps>) {
  const ref = useRef<HTMLDivElement>(null);

  const query = FOCUSABLE_TAGS.split(', ')
    .map((tag) => `:scope > ul > li > ${tag}`)
    .join(', ');
  useFocusTrap(ref, isActive && !isSubActive, query);
  useKeyPress('Escape', () => {
    closeTier1();
  });

  return (
    <div
      id={id}
      ref={ref}
      className={twMerge(
        styles({
          filler,
          isActive,
          isSubActive,
        }),
      )}
    >
      {children}
    </div>
  );
}
