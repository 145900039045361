import {cva} from 'class-variance-authority';
import {type SyntheticEvent, useRef, useEffect} from 'react';

import {useSearchFormHelpers} from '@/pages/shopify.com/($locale)/partners/directory/utilities/useSearchFormHelpers';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {CancelIcon} from '@/pages/shopify.com/($locale)/partners/directory/components/CancelIcon/CancelIcon';

import SearchIcon from './components/SearchIcon';
import SearchFlyout from './components/SearchFlyout';

const styles = cva(
  'flex-auto py-3 px-2 w-full outline-none bg-transparent placeholder:text-subdued',
  {
    variants: {
      isSearchFocused: {
        true: '',
        false: '',
      },
    },
  },
);

interface SearchProps {
  hideSearchResults?: boolean;
  isSearchFocused?: boolean;
  setIsSearchFocused?: (isSearchFocused: boolean) => void;
}

export default function Search({
  hideSearchResults,
  isSearchFocused,
  setIsSearchFocused,
}: SearchProps) {
  const {
    formRef,
    Form,
    showFlyout,
    searchTerm,
    partners,
    services,
    countries,
    dismissSearchResults,
    onChange,
    errors,
  } = useSearchFormHelpers();
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hideSearchResults && searchInputRef.current) {
      searchInputRef.current.value = '';
      dismissSearchResults();
    }
  }, [dismissSearchResults, hideSearchResults]);

  return (
    <Form ref={formRef} className="flex-auto md:mx-8" onChange={onChange}>
      <div className="flex items-center h-12 rounded-full relative py-3 border border-eco-input-stroke-default bg-eco-input-canvas-default">
        <SearchIcon className="flex-auto w-[1.375rem] ml-4" />
        <input
          ref={searchInputRef}
          type="text"
          name="directory-search"
          placeholder={t('search.placeholder')}
          className={styles({isSearchFocused})}
          onFocus={() => setIsSearchFocused?.(true)}
        />
        {showFlyout ? (
          <>
            <button
              type="button"
              name="clear_search_for_hero_search"
              className="w-5 h-5 mr-4"
              onClick={(event: SyntheticEvent) => {
                event?.preventDefault();
                if (searchInputRef.current) {
                  searchInputRef.current.value = '';
                }
              }}
            >
              <CancelIcon />
            </button>
            <SearchFlyout
              partners={partners || []}
              services={services || []}
              countries={countries || []}
              dismissSearchResults={dismissSearchResults}
              formRef={formRef}
              errors={errors}
              searchTerm={searchTerm}
            />
          </>
        ) : null}
      </div>
    </Form>
  );
}
