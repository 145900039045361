import React from 'react';

export default function Back({
  className,
}: React.HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3685_55977)">
        <path
          d="M22.8 10.8H4.39319L11.5896 4.50477C11.8273 4.29483 11.9723 3.99929 11.9928 3.68278C12.0133 3.36626 11.9076 3.0545 11.6989 2.81567C11.4902 2.57683 11.1954 2.43033 10.879 2.4082C10.5626 2.38607 10.2503 2.49011 10.0104 2.69757L0.41039 11.0976C0.40079 11.106 0.39719 11.1192 0.38759 11.1276C0.302441 11.2121 0.230803 11.3092 0.17519 11.4156C0.15359 11.4516 0.12119 11.4804 0.10439 11.52C0.0361472 11.6712 0.000854492 11.8352 0.000854492 12.0012C0.000854492 12.1671 0.0361472 12.3311 0.10439 12.4824C0.12119 12.522 0.15359 12.5508 0.17519 12.588C0.23519 12.6924 0.29999 12.792 0.38759 12.8748C0.39719 12.8832 0.40079 12.8964 0.41039 12.9048L10.0104 21.3048C10.1288 21.4091 10.2666 21.4889 10.416 21.5396C10.5654 21.5904 10.7233 21.611 10.8807 21.6004C11.0381 21.5898 11.1919 21.5482 11.3331 21.4779C11.4743 21.4076 11.6003 21.31 11.7036 21.1908C11.913 20.9511 12.0186 20.6381 11.9973 20.3206C11.9759 20.003 11.8292 19.707 11.5896 19.4976L4.39319 13.2H22.8C23.1182 13.2 23.4235 13.0735 23.6485 12.8485C23.8736 12.6235 24 12.3182 24 12C24 11.6817 23.8736 11.3765 23.6485 11.1514C23.4235 10.9264 23.1182 10.8 22.8 10.8Z"
          fill="#616A75"
        />
      </g>
      <defs>
        <clipPath id="clip0_3685_55977">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
