import {useEffect, useState} from 'react';

import useGlobalNav from '@/hooks/navigation/global';
import Button from '@/components/base/elements/Button/Button';
import type {PartnersDirectoryUser} from '@/pages/shopify.com/($locale)/partners/directory/types';

import UserMenu from './components/UserMenu';

interface Props {
  user?: PartnersDirectoryUser;
}

export default function CtaLinks({user}: Props) {
  const {loginText} = useGlobalNav();
  const [currentUrl, setCurrentUrl] = useState(
    'https://shopify.com/partners/directory',
  );
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const loggedOutLink = (
    <>
      <li className="text-base flex items-center">
        <Button
          href={`/services/identity/login?return_to=${currentUrl}`}
          componentName="partners-login"
          intent="secondary"
          size="small"
        >
          {loginText}
        </Button>
      </li>
    </>
  );

  const loggedInLink = (
    <li className="text-base relative">
      <UserMenu user={user} />
    </li>
  );

  return user ? loggedInLink : loggedOutLink;
}
