import {useCallback, useEffect} from 'react';

import {useSharedTranslations} from '@/hooks/useTranslations';
import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import type {
  CountriesResults,
  PartnersResults,
  ServicesResults,
} from '@/pages/shopify.com/($locale)/partners/directory/utilities/useSearchFormHelpers';
import {useFocusTrap} from '@/hooks/useFocusTrap';
import {ListingsErrorBanner} from '@/pages/shopify.com/($locale)/partners/directory/components/ListingsErrorBanner/ListingsErrorBanner';

import SearchResults from './SearchResults';
import BrowseAllServices from './BrowseAllServices';

interface SearchFlyoutProps {
  partners: PartnersResults[];
  services: ServicesResults[];
  countries: CountriesResults[];
  dismissSearchResults: () => void;
  formRef: React.RefObject<HTMLFormElement>;
  errors: string | string[];
  searchTerm?: string;
}

export default function SearchFlyout({
  partners,
  services,
  countries,
  dismissSearchResults,
  formRef,
  errors,
  searchTerm,
}: SearchFlyoutProps) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  useOnClickOutside(dismissSearchResults);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const focusableElements: HTMLAnchorElement[] = Array.from(
        formRef.current?.querySelectorAll('a[href]') || [],
      );
      const currentlyFocusedElement = document.activeElement;

      if (event.key === 'Escape') {
        dismissSearchResults();
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        // go to next focusable element
        if (currentlyFocusedElement) {
          const currentlyFocusedElementIndex = focusableElements.indexOf(
            currentlyFocusedElement as HTMLAnchorElement,
          );
          const nextElement =
            focusableElements?.[currentlyFocusedElementIndex + 1] ||
            focusableElements?.[0];
          nextElement?.focus();
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        // go to previous focusable element
        if (currentlyFocusedElement) {
          const currentlyFocusedElementIndex = focusableElements.indexOf(
            currentlyFocusedElement as HTMLAnchorElement,
          );
          const previousElement =
            focusableElements?.[currentlyFocusedElementIndex - 1] ||
            focusableElements?.[focusableElements.length - 1];
          previousElement?.focus();
        }
      } else if (event.key === 'Enter') {
        // don't shift focus on clear button action
        if (currentlyFocusedElement?.nodeName === 'BUTTON') {
          return;
        }
        if (
          focusableElements.indexOf(
            currentlyFocusedElement as HTMLAnchorElement,
          ) === -1
        ) {
          focusableElements[0]?.focus();
        }
      }
    },
    [dismissSearchResults, formRef],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useFocusTrap(formRef, true, 'a[href]');

  let errorState;
  if (Array.isArray(errors) && errors.length > 0) {
    errorState = <ListingsErrorBanner errorMessage={t('search.error')} />;
  }

  return (
    <div
      className="absolute top-[calc(100%+6px)] left-0 flex flex-col w-full  z-modal shadow-light rounded-lg bg-white p-2 max-h-128 overflow-y-auto"
      data-click-outside="dismiss"
    >
      {errorState || (
        <SearchResults
          services={services}
          partners={partners}
          countries={countries}
          dismissSearchResults={dismissSearchResults}
          searchTerm={searchTerm}
        />
      )}
      <BrowseAllServices dismissSearchResults={dismissSearchResults} />
    </div>
  );
}
