import {useNavigation} from '@remix-run/react';

import type {
  CountriesResults,
  PartnersResults,
  ServicesResults,
} from '@/pages/shopify.com/($locale)/partners/directory/utilities/useSearchFormHelpers';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';

interface SearchResultsProps {
  services: ServicesResults[];
  partners: PartnersResults[];
  countries: CountriesResults[];
  dismissSearchResults: () => void;
  searchTerm?: string;
}

export default function SearchResults({
  services,
  partners,
  countries,
  dismissSearchResults,
  searchTerm,
}: SearchResultsProps) {
  const {localizePath} = useTranslations();
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const navigation = useNavigation();

  const hasServices = services?.length > 0;
  const hasPartners = partners?.length > 0;
  const hasCountries = countries?.length > 0;

  return (
    <>
      {!hasServices &&
        !hasPartners &&
        !hasCountries &&
        navigation.state !== 'idle' && (
          <div className="flex-auto flex py-11">
            <svg
              className="flex-auto animate-spin w-24 h-24 fill-emerald-200"
              viewBox="0 0 44 44"
            >
              <path d="M15.542 1.487A21.507 21.507 0 00.5 22c0 11.874 9.626 21.5 21.5 21.5 9.847 0 18.364-6.675 20.809-16.072a1.5 1.5 0 00-2.904-.756C37.803 34.755 30.473 40.5 22 40.5 11.783 40.5 3.5 32.217 3.5 22c0-8.137 5.3-15.247 12.942-17.65a1.5 1.5 0 10-.9-2.863z" />
            </svg>
          </div>
        )}
      {(hasServices || hasPartners || hasCountries) && (
        <>
          <section className="flex-auto px-2 py-3">
            <Typography isRichtext className="text-sm text-subdued" as="span">
              {t('search.navigationHelpHtml')}
            </Typography>
          </section>
          {hasServices && (
            <section className="flex-auto">
              <h1 className="text-sm pt-3 px-2 text-subdued font-bold">
                {t('search.services')}
              </h1>
              <ul>
                {services.map(
                  (service: {
                    serviceHandle: string;
                    label: string;
                    description: string;
                    parentHandle: string;
                  }) => (
                    <li key={service.serviceHandle}>
                      <a
                        className="flex flex-col bg-transparent py-3 px-2 rounded-lg hover:bg-slate-100 active:bg-slate-100 focus:bg-slate-100"
                        href={localizePath(
                          `/partners/directory/services/${service.parentHandle}/${service.serviceHandle}`,
                        )}
                        onClick={() => dismissSearchResults()}
                      >
                        <h2 className="flex-auto text-t8">{service.label}</h2>
                        <p className="flex-auto text-sm text-subdued">
                          {service.description}
                        </p>
                      </a>
                    </li>
                  ),
                )}
              </ul>
            </section>
          )}
          {hasPartners && (
            <section className="flex-auto">
              <h1 className="text-sm pt-3 px-2 text-subdued font-bold">
                {t('search.partners')}
              </h1>
              <ul>
                {partners.map((partner: PartnersResults) => (
                  <li key={partner.partnerHandle}>
                    <a
                      className="flex items-center py-3 px-2 gap-x-4 rounded-lg bg-transparent hover:bg-slate-100 active:bg-slate-100 focus:bg-slate-100"
                      href={localizePath(
                        `/partners/directory/partner/${partner.partnerHandle}`,
                      )}
                      onClick={() => dismissSearchResults()}
                    >
                      <img
                        src={partner.avatarUrl}
                        alt={partner.name}
                        className="h-6 w-6 rounded-full"
                      />
                      <h2 className="flex-auto text-t8">{partner.name}</h2>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}
          {hasCountries && (
            <section className="flex-auto">
              <h1 className="text-sm pt-3 px-2 text-subdued font-bold">
                {t('search.locations.heading')}
              </h1>
              <ul>
                {countries.map((country: CountriesResults) => (
                  <li key={country.countryCode}>
                    <a
                      className="flex flex-col bg-transparent py-3 px-2 rounded-lg hover:bg-slate-100 active:bg-slate-100 focus:bg-slate-100"
                      href={localizePath(
                        `/partners/directory/locations/${country.countryHandle}`,
                      )}
                      onClick={() => dismissSearchResults()}
                    >
                      <h2 className="flex-auto text-t8">{country.name}</h2>
                      <p className="flex-auto text-sm text-subdued">
                        {t('search.locations.partnerCount', {
                          count: country.partnerCount,
                        })}
                      </p>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}
          <div className="border-t border-subdued-light mx-2" />
        </>
      )}

      {!hasServices &&
        !hasPartners &&
        !hasCountries &&
        navigation.state === 'idle' && (
          <>
            <section className="py-8 text-center">
              <p className="text-t7 text-subdued flex-auto px-2 py-3">
                {searchTerm
                  ? t('search.noResults')
                  : t('search.fetchingResults')}
              </p>
            </section>

            <div className="border-t border-subdued-light mx-2" />
          </>
        )}
    </>
  );
}
