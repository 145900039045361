import {Link} from '@remix-run/react';
import {useCallback, useEffect, useRef, useState, useMemo} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Hamburger from '@/components/brochureV2/section/Header/Hamburger';
import MobileMainNavigation from '@/components/brochureV2/section/Header/MobileMainNavigation';
import type {HeaderProps as BaseHeaderProps} from '@/components/brochureV2/section/Header/types';
import SecondaryNavigation from '@/pages/shopify.com/($locale)/partners/directory/components/Header/components/SecondaryNavigation/SecondaryNavigation';
import Button from '@/components/base/elements/Button/Button';
import {HeaderBackgroundColorScheme, ScrollDirection} from '@/enums';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {useScrollDirection} from '@/hooks/useScrollDirection';
import {useScrollThresholds} from '@/hooks/useScrollThresholds';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import {
  headerContainer2Styles,
  headerContainerStyles,
  headerStyles,
  secondaryNavigationContainerStyles,
} from '@/components/brochureV2/section/Header/styles';
import type {
  PartnersDirectoryUser,
  SecondaryNavigationType,
} from '@/pages/shopify.com/($locale)/partners/directory/types';

import PartnersLogo from './components/PartnersLogo/PartnersLogo';
import SearchIcon from './components/Search/components/SearchIcon';
import Search from './components/Search/Search';
import Back from './components/Back/Back';
import {searchStyles, logoStyles, mobileCtaLinkListStyles} from './styles';
import DesktopUserMenu from './components/DesktopCtaLinklist/components/DesktopUserMenu/DesktopUserMenu';
import MobileUserMenu from './components/MobileUserMenu/MobileUserMenu';

interface HeaderProps extends BaseHeaderProps {
  user?: PartnersDirectoryUser;
  secondaryNavPageTitle: string;
}

export default function Header({
  background,
  className,
  CustomMobileMenuCtaLinklist,
  mainNavFn,
  secondaryNavFn,
  secondaryNavPageTitle,
  user,
}: HeaderProps) {
  // Translation/Localization/Content
  const translations = useTranslations();
  const {localizePath} = translations;
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const mainNav = useMemo(
    () =>
      mainNavFn
        ? mainNavFn(translations)
        : [
            {
              content: t('header.userMenu.help'),
              url: 'https://help.shopify.com/en/partners/directory',
              data: {
                gaEvent: 'Main Nav',
                gaAction: 'Help centre',
              },
            },
          ],
    [mainNavFn, translations, t],
  );
  const secondaryNav = useMemo(
    () =>
      secondaryNavFn
        ? ({
            ...secondaryNavFn(translations),
            moreText: translations.t('nav:more'),
          } as SecondaryNavigationType)
        : undefined,
    [secondaryNavFn, translations],
  );

  const hasSecondaryNav =
    (secondaryNav?.links && secondaryNav.links.length > 0) ?? false;

  if (secondaryNav && hasSecondaryNav) {
    secondaryNav.pageTitle = secondaryNavPageTitle;
  }

  const backgroundColorScheme =
    background || HeaderBackgroundColorScheme.TransparentLight;
  const darkBackground =
    backgroundColorScheme === HeaderBackgroundColorScheme.Black ||
    backgroundColorScheme === HeaderBackgroundColorScheme.TransparentDark;

  const [isPrimaryNavigationInViewport, setIsPrimaryNavigationInViewport] =
    useState(true);

  const [
    activeDesktopMainNavigationIndex,
    setActiveDesktopMainNavigationIndex,
  ] = useState(-1);
  const [activeMobileMainNavigationIndex, setActiveMobileMainNavigationIndex] =
    useState(-1);
  const [isFixed, setIsFixed] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const mainHeaderRef = useRef<HTMLDivElement>(null);

  const mainHeaderInnerRef = useRef<HTMLDivElement>(null);
  const primaryNavigationOuterContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 1119px)');
  const wasMobileRef = useRef<boolean | null>(null);

  const scrollDirection = useScrollDirection();
  const scrollThreshold = useScrollThresholds([0, 1, 71, 141]);

  // The menu should close when Escape is pressed
  useKeyPress('Escape', () => {
    setIsMobileMenuOpen(false);
    setActiveDesktopMainNavigationIndex(-1);
    setActiveMobileMainNavigationIndex(-1);
  });

  const memoizedHandleIntersectCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsPrimaryNavigationInViewport(true);
        } else {
          setIsPrimaryNavigationInViewport(false);
        }
      });
    },
    [setIsPrimaryNavigationInViewport],
  );

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  useIntersectionObserver(
    primaryNavigationOuterContainerRef,
    memoizedHandleIntersectCallback,
    options,
  );

  useEffect(() => {
    if (scrollThreshold === 0) {
      setIsFixed(false);
    } else if (hasSecondaryNav && !isPrimaryNavigationInViewport) {
      setIsFixed(true);
    } else if (!hasSecondaryNav && scrollThreshold > 0) {
      setIsFixed(true);
    }
  }, [scrollThreshold, hasSecondaryNav, isPrimaryNavigationInViewport]);

  // Once scrolled the background color of the header changes if initially transparent
  useEffect(() => {
    if (scrollThreshold > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [scrollThreshold]);

  // When transitioning between mobile and desktop breaskpoints, the menu should be closed
  useEffect(() => {
    if (isMobile === null) return;

    if (isMobile && !wasMobileRef.current) {
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    } else if (!isMobile && wasMobileRef.current) {
      setIsMobileMenuOpen(false);
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    }

    wasMobileRef.current = isMobile;
  }, [isMobile]);

  const hasHamburger =
    mainNav.length > 0 || CustomMobileMenuCtaLinklist !== null;

  const [currentUrl, setCurrentUrl] = useState(
    'https://shopify.com/partners/directory',
  );
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <header
      ref={mainHeaderRef}
      className={twMerge(headerStyles({isFixed, hasSecondaryNav}), className)}
      data-component-name="global-nav"
      data-has-secondary-navigation={hasSecondaryNav}
    >
      <div
        ref={mainHeaderInnerRef}
        className={twMerge(
          headerContainerStyles({
            backgroundColorScheme,
            hasSecondaryNav,
            isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
            isFixed,
            isMobileMenuOpen,
            isScrolled,
            isThresholdPassed: scrollThreshold < 72,
            scrollDirection,
          }),
        )}
      >
        <div
          className={twMerge(
            headerContainer2Styles({
              backgroundColorScheme,
              hasSecondaryNav,
              isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
              isFixed,
              isMobileMenuOpen,
            }),
          )}
        >
          <div
            ref={primaryNavigationOuterContainerRef}
            className="h-global-header container flex items-center relative z-10"
          >
            <a href="/partners/directory" aria-label={t('header.nav.logo')}>
              <PartnersLogo
                className={logoStyles({isSearchFocused})}
                aria-hidden="true"
              />
            </a>
            <div className={searchStyles({isSearchFocused})}>
              <button
                onClick={() => setIsSearchFocused(false)}
                aria-label={t('search.back')}
              >
                <Back className="nav:hidden" aria-hidden="true" />
              </button>
              <Search
                hideSearchResults={isFixed}
                isSearchFocused={isSearchFocused}
                setIsSearchFocused={setIsSearchFocused}
              />
            </div>
            <div className="ml-auto">
              <ul className="nav:flex hidden items-center">
                {user ? (
                  <li>
                    <DesktopUserMenu user={user} />
                  </li>
                ) : (
                  <li className="leading-[0]">
                    <Button
                      href={`/services/identity/login?return_to=${currentUrl}`}
                      size="small"
                      intent="secondary"
                      componentName="Login"
                    >
                      {translations.t('nav:login')}
                    </Button>
                  </li>
                )}
              </ul>
              <ul
                className={twMerge(
                  mobileCtaLinkListStyles({hasHamburger, isSearchFocused}),
                )}
              >
                <li>
                  <button
                    className="flex h-12 w-12 cursor-pointer select-none flex-col items-center justify-center gap-1"
                    onClick={() => setIsSearchFocused(true)}
                    aria-label={t('search.open')}
                  >
                    <SearchIcon className="w-6" aria-hidden="true" />
                  </button>
                </li>
                {hasHamburger && (
                  <li>
                    <Hamburger
                      background={backgroundColorScheme}
                      isActive={isMobileMenuOpen}
                      onToggle={() => {
                        setIsMobileMenuOpen(!isMobileMenuOpen);
                        setActiveDesktopMainNavigationIndex(-1);
                        setActiveMobileMainNavigationIndex(-1);
                      }}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {hasSecondaryNav && (
          <div
            className={twMerge(
              secondaryNavigationContainerStyles({
                backgroundColorScheme,
                hasSecondaryNav,
                isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
                isFixed,
                isMobileMenuOpen,
              }),
            )}
          >
            <div className="h-global-header container flex items-center">
              <SecondaryNavigation
                secondaryNav={secondaryNav}
                mode={darkBackground ? 'dark' : 'light'}
                showGlobals={
                  isScrolled &&
                  isFixed &&
                  scrollDirection === ScrollDirection.Down
                }
                homeUrl={localizePath('/partners/directory')}
                user={user}
              />
            </div>
          </div>
        )}
      </div>
      <MobileMainNavigation
        logo={
          <Link to="/partners/directory" aria-label={t('header.nav.logo')}>
            <PartnersLogo
              className={logoStyles({isSearchFocused})}
              aria-hidden="true"
            />
          </Link>
        }
        mobileCtaLinklist={
          <button
            className="flex h-12 w-12 cursor-pointer select-none flex-col items-center justify-center gap-1"
            onClick={() => {
              setIsSearchFocused(true);
              setIsMobileMenuOpen(false);
            }}
            aria-label={t('search.open')}
          >
            <SearchIcon className="w-6" aria-hidden="true" />
          </button>
        }
        CustomMobileMenuCtaLinklist={
          user ? (
            <div className="flex sm:items-center flex-col sm:flex-row w-full">
              <li className="pb-3 sm:pb-0">
                <MobileUserMenu user={user} />
              </li>
              <li className="py-1 sm:pt-2 grow">
                <Button
                  href={`/services/identity/logout?return_to=${currentUrl}`}
                  intent="secondary"
                  className="w-full"
                  mode={darkBackground ? 'dark' : 'light'}
                  componentName="logout"
                >
                  {t('header.userMenu.logout')}
                </Button>
              </li>
            </div>
          ) : (
            <li className="py-1 sm:grow sm:pt-2">
              <Button
                href={`/services/identity/login?return_to=${currentUrl}`}
                intent="secondary"
                componentName="Login"
                className="w-full"
              >
                {translations.t('nav:login')}
              </Button>
            </li>
          )
        }
        openIndex={activeMobileMainNavigationIndex}
        setOpenIndex={setActiveMobileMainNavigationIndex}
        isMobileMenuOpen={isMobileMenuOpen}
        background={backgroundColorScheme}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        setActiveDesktopMainNavigationIndex={
          setActiveDesktopMainNavigationIndex
        }
        setActiveMobileMainNavigationIndex={setActiveMobileMainNavigationIndex}
        mainNav={mainNav}
      />
    </header>
  );
}
