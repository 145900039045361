import {useEffect, useRef, useState} from 'react';

import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import FlyoutList from '@/components/base/sections/Header/modules/SecondaryNavigation/components/FlyoutList';
import FlyoutWrapper from '@/components/base/sections/Header/modules/SecondaryNavigation/components/FlyoutWrapper';
import Tier1Link from '@/components/base/sections/Header/modules/SecondaryNavigation/components/Tier1Link';
import Tier2Link from '@/components/base/sections/Header/modules/SecondaryNavigation/components/Tier2Link';
import type {Link} from '@/components/base/sections/Header/modules/SecondaryNavigation/components/types';
import Icon from '@/components/base/elements/Icon/Icon';
import {IconEnum} from '@/enums';

import DropdownWrapper from './components/DropdownWrapper';

interface DesktopProps {
  path: string;
  links: Link[];
}

export function DesktopNavigation({links, path}: DesktopProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeSubIndex, setActiveSubIndex] = useState<number | null>(null);
  const [filler, setFiller] = useState<any>(null);

  const tier1LinkRefs = useRef<any>([]);
  const tier2LinkRefs = useRef<any>([]);

  useEffect(() => {
    activeIndex === null && activeSubIndex !== null && setActiveSubIndex(null);
  }, [activeIndex, activeSubIndex]);

  useEffect(() => {
    activeSubIndex === null && setFiller(null);
  }, [activeSubIndex]);

  const closeTier1 = () => {
    setActiveIndex(null);
    activeIndex !== null && tier1LinkRefs.current[activeIndex].focus();
  };

  const closeTier2 = () => {
    setActiveSubIndex(null);
    activeIndex !== null &&
      activeIndex !== null &&
      activeSubIndex !== null &&
      tier2LinkRefs.current[activeIndex][activeSubIndex].focus();
  };

  useOnClickOutside(closeTier1);

  return (
    <nav
      aria-label="Secondary"
      className="hidden lg:block h-full"
      data-click-outside="dismiss"
    >
      <ul className="flex items-center gap-x-8 h-full">
        {links.map((tier1Link: Link, tier1Index: number) => (
          <Tier1Link
            key={`${tier1Link.text}${tier1Index}desktop`}
            isActive={activeIndex === tier1Index}
            mode="light"
          >
            {!tier1Link.links ? (
              <a
                href={tier1Link.url}
                className="flex items-center h-full outline-state-focus outline-4 focus-visible:outline"
                data-secondary-nav-tier="1"
              >
                {tier1Link.text}
              </a>
            ) : (
              <>
                <button
                  ref={(element: HTMLButtonElement) =>
                    (tier1LinkRefs.current[tier1Index] = element)
                  }
                  type="button"
                  aria-expanded={activeIndex === tier1Index}
                  aria-controls={`id_${tier1Link.text}_${tier1Index}`}
                  className="h-full outline-state-focus outline-4 focus-visible:outline"
                  onClick={() => {
                    setActiveIndex(
                      activeIndex === tier1Index ? null : tier1Index,
                    );
                    setActiveSubIndex(null);
                    setFiller(null);
                  }}
                  data-secondary-nav-tier="1"
                >
                  <span className="flex items-center">
                    {tier1Link.text}
                    <Icon
                      icon={IconEnum.Chevron}
                      className="shrink-0 w-5 h-7 ml-2"
                      options={{
                        orientation: activeIndex !== tier1Index ? 'down' : 'up',
                      }}
                    />
                  </span>
                </button>
                <DropdownWrapper
                  id={`id_${tier1Link.text}_${tier1Index}`}
                  closeTier1={closeTier1}
                  filler={filler}
                  isActive={activeIndex === tier1Index}
                  isSubActive={typeof activeSubIndex === 'number'}
                >
                  <ul className="p-4 w-[240px] box-content">
                    {tier1Link.links.map(
                      (tier2Link: Link, tier2Index: number) => (
                        <Tier2Link
                          key={`${tier2Link.text}${tier2Index}desktop`}
                          isActive={activeSubIndex === tier2Index}
                          mode="light"
                        >
                          {!tier2Link.links ? (
                            <a
                              href={tier2Link.url}
                              aria-current={
                                tier2Link.url === path ? 'page' : undefined
                              }
                              className="hover:underline outline-state-focus outline-4 focus-visible:outline break-normal whitespace-normal text-left"
                              data-secondary-nav-tier="2"
                            >
                              {tier2Link.text}
                            </a>
                          ) : (
                            <>
                              <button
                                ref={(element: HTMLButtonElement) =>
                                  (tier2LinkRefs.current[tier1Index] = {
                                    ...tier2LinkRefs.current[tier1Index],
                                    [tier2Index]: element,
                                  })
                                }
                                type="button"
                                aria-expanded={activeSubIndex === tier2Index}
                                aria-controls={`id_${tier2Link.text}_${tier2Index}`}
                                className="w-full outline-state-focus outline-4 focus-visible:outline"
                                onClick={() => {
                                  const links1 = tier1Link.links?.length || 0;
                                  const links2 = tier2Link.links?.length || 0;
                                  setFiller(
                                    activeSubIndex === tier2Index
                                      ? null
                                      : links2 - links1,
                                  );
                                  setActiveSubIndex(
                                    activeSubIndex === tier2Index
                                      ? null
                                      : tier2Index,
                                  );
                                }}
                                data-secondary-nav-tier="2"
                              >
                                <span className="flex items-center w-full hover:underline break-normal whitespace-normal text-left">
                                  {tier2Link.text}
                                  <Icon
                                    icon={IconEnum.Chevron}
                                    className="shrink-0 w-5 h-7 ml-auto"
                                    options={{
                                      orientation: 'right',
                                    }}
                                  />
                                </span>
                              </button>
                              <FlyoutWrapper
                                id={`id_${tier2Link.text}_${tier2Index}`}
                                isActive={activeSubIndex === tier2Index}
                              >
                                <FlyoutList
                                  isActive={activeSubIndex === tier2Index}
                                  mode="light"
                                  closeTier2={closeTier2}
                                >
                                  {tier2Link.links.map(
                                    (tier3Link: Link, tier3Index: number) => (
                                      <li
                                        key={`${tier3Link.text}${tier3Index}desktop`}
                                        className="py-3 px-4"
                                      >
                                        <a
                                          href={tier3Link.url}
                                          aria-current={
                                            tier3Link.url === path
                                              ? 'page'
                                              : undefined
                                          }
                                          className="hover:underline outline-state-focus outline-4 focus-visible:outline break-normal whitespace-normal text-left"
                                          data-secondary-nav-tier="3"
                                        >
                                          {tier3Link.text}
                                        </a>
                                      </li>
                                    ),
                                  )}
                                </FlyoutList>
                              </FlyoutWrapper>
                            </>
                          )}
                        </Tier2Link>
                      ),
                    )}
                  </ul>
                </DropdownWrapper>
              </>
            )}
          </Tier1Link>
        ))}
      </ul>
    </nav>
  );
}
