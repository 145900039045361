import type {PartnersDirectoryUser} from '@/pages/shopify.com/($locale)/partners/directory/types';

import DefaultAvatar from '../DesktopCtaLinklist/components/DesktopUserMenu/components/DefaultAvatar';

interface Props {
  user?: PartnersDirectoryUser;
}

export default function MobileUserMenu({user}: Props) {
  return (
    <div className="flex items-center gap-x-4">
      {user?.avatar ? (
        <div className="w-12 h-12 rounded-full overflow-hidden">
          <img
            src={user.avatar}
            alt={user.storeName}
            className="w-full h-full object-cover"
          />
        </div>
      ) : (
        <DefaultAvatar />
      )}

      <div className="flex flex-col w-full truncate items-start">
        <span className="text-body-sm font-semibold">{user?.storeName}</span>
        <span className="text-body-sm text-eco-fg-secondary">
          {user?.email}
        </span>
      </div>
    </div>
  );
}
