import type {I18NOptions} from '@/types';

interface SecondaryNavLinks {
  text: string;
  name: string;
  url?: string;
  links?: SecondaryNavLinks[];
}

const secondaryNavFn = ({t, localizePath}: I18NOptions) => {
  const links: SecondaryNavLinks[] = [
    {
      text: t('pages/partners/directory/shared:secondaryNav.browseAll'),
      name: 'browse-all',
      url: localizePath('/partners/directory/services'),
    },
    {
      text: t('pages/partners/directory/shared:secondaryNav.serviceCategories'),
      name: 'service-categories',
      links: [
        {
          text: t(
            'pages/partners/directory/shared:secondaryNav.marketingAndSales',
          ),
          name: 'marketing-and-sales',
          links: [
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.emailMarketing',
              ),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/email-marketing',
              ),
              name: 'email-marketing',
            },
            {
              text: t('pages/partners/directory/shared:secondaryNav.seo'),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/seo-and-paid-search',
              ),
              name: 'seo',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.searchEngineAdvertising',
              ),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/setup-search-engine-advertising-campaigns',
              ),
              name: 'search-engine-advertising',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.socialMediaMarketing',
              ),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/social-media-marketing',
              ),
              name: 'social-media-marketing',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.contentMarketing',
              ),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/content-marketing',
              ),
              name: 'content-marketing',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.salesChannelSetup',
              ),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/sales-channel-setup',
              ),

              name: 'sales-channel-setup',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.conversionRateOptimization',
              ),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/conversion-rate-optimization',
              ),
              name: 'conversion-rate-optimization',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.analyticsAndTracking',
              ),
              url: localizePath(
                '/partners/directory/services/marketing-and-sales/analytics-and-tracking',
              ),
              name: 'analytics-and-tracking',
            },
          ],
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.storeSetup'),
          name: 'store-setup',
          links: [
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.storeBuildOrRedesign',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/customize-design',
              ),
              name: 'store-build-or-redesign',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.storeMigration',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/migrate-from-another-platform',
              ),
              name: 'store-migration',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.productAndCollectionSetup',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/set-up-products-and-collections',
              ),
              name: 'product-and-collection-setup',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.themeCustomization',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/customize-theme',
              ),
              name: 'theme-customization',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.storeSettingsConfiguration',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/configure-settings',
              ),
              name: 'store-settings-configuration',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.posSetupAndMigration',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/pos-setup-and-migration',
              ),
              name: 'pos-setup-and-migration',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.headlessCommerce',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/headless-commerce',
              ),
              name: 'headless-commerce',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.websiteAuditAndOptimizationStrategy',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/website-audit-and-optimization-strategy',
              ),
              name: 'website-audit-and-optimization-strategy',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.ongoingWebsiteManagement',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/ongoing-website-management',
              ),
              name: 'ongoing-website-management',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.checkoutUpgrade',
              ),
              url: localizePath(
                '/partners/directory/services/store-setup/checkout-upgrade',
              ),
              name: 'checkout-upgrade',
            },
          ],
        },
        {
          text: t(
            'pages/partners/directory/shared:secondaryNav.developmentAndTroubleshooting',
          ),
          name: 'development-and-troubleshooting',
          links: [
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.customAppsAndIntegrations',
              ),
              url: localizePath(
                '/partners/directory/services/development-and-troubleshooting/custom-apps-integrations',
              ),
              name: 'custom-apps-and-integrations',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.customDomainSetup',
              ),
              url: localizePath(
                '/partners/directory/services/development-and-troubleshooting/domain-setup',
              ),
              name: 'custom-domain-setup',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.troubleshooting',
              ),
              url: localizePath(
                '/partners/directory/services/development-and-troubleshooting/troubleshooting-and-problem-resolution',
              ),
              name: 'troubleshooting',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.systemsIntegration',
              ),
              url: localizePath(
                '/partners/directory/services/development-and-troubleshooting/systems-integration',
              ),
              name: 'systems-integration',
            },
          ],
        },
        {
          text: t(
            'pages/partners/directory/shared:secondaryNav.visualContentAndBranding',
          ),
          name: 'visual-content-and-branding',
          links: [
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.logoAndVisualBranding',
              ),
              url: localizePath(
                '/partners/directory/services/visual-content-and-branding/develop-brand-look-and-feel',
              ),
              name: 'logo-and-visual-branding',
            },
            {
              text: t('pages/partners/directory/shared:secondaryNav.bannerAds'),
              url: localizePath(
                '/partners/directory/services/visual-content-and-branding/create-custom-banner-ads',
              ),
              name: 'banner-ads',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.videoAndIllustrations',
              ),
              url: localizePath(
                '/partners/directory/services/visual-content-and-branding/video-and-illustrations',
              ),
              name: 'video-and-illustrations',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.productPhotography',
              ),
              url: localizePath(
                '/partners/directory/services/visual-content-and-branding/take-product-photos',
              ),
              name: 'product-photography',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.3dModelling',
              ),
              url: localizePath(
                '/partners/directory/services/visual-content-and-branding/create-3d-models-ar',
              ),
              name: '3d-modelling',
            },
          ],
        },
        {
          text: t(
            'pages/partners/directory/shared:secondaryNav.contentWriting',
          ),
          name: 'content-writing',
          links: [
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.productDescriptions',
              ),
              url: localizePath(
                '/partners/directory/services/content-writing/write-product-descriptions',
              ),
              name: 'product-descriptions',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.websiteAndMarketingContent',
              ),
              url: localizePath(
                '/partners/directory/services/content-writing/website-marketing-content',
              ),
              name: 'website-and-marketing-content',
            },
          ],
        },
        {
          text: t(
            'pages/partners/directory/shared:secondaryNav.expertGuidance',
          ),
          name: 'expert-guidance',
          links: [
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.businessStrategyGuidance',
              ),
              url: localizePath(
                '/partners/directory/services/expert-guidance/business-strategy-guidance',
              ),
              name: 'business-strategy-guidance',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.productSourcingGuidance',
              ),
              url: localizePath(
                '/partners/directory/services/expert-guidance/product-sourcing-guidance',
              ),
              name: 'product-sourcing-guidance',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.sitePerformanceAndSpeed',
              ),
              url: localizePath(
                '/partners/directory/services/expert-guidance/guidance-for-improving-your-site-performance-and-speed',
              ),
              name: 'site-performance-and-speed',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.salesTaxGuidance',
              ),
              url: localizePath(
                '/partners/directory/services/expert-guidance/tax-setup',
              ),
              name: 'sales-tax-guidance',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.wholesaleOrB2b',
              ),
              url: localizePath(
                '/partners/directory/services/expert-guidance/wholesale-or-b2b',
              ),
              name: 'wholesale-or-b2b',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.internationalExpansion',
              ),
              url: localizePath(
                '/partners/directory/services/expert-guidance/international-expansion',
              ),
              name: 'international-expansion',
            },
            {
              text: t(
                'pages/partners/directory/shared:secondaryNav.productDevelopment',
              ),
              url: localizePath(
                '/partners/directory/services/expert-guidance/product-development',
              ),
              name: 'product-development',
            },
          ],
        },
      ],
    },
    {
      text: t('pages/partners/directory/shared:secondaryNav.locations'),
      name: 'locations',
      links: [
        {
          text: t('pages/partners/directory/shared:secondaryNav.unitedStates'),
          name: 'united-states',
          url: localizePath('/partners/directory/locations/united-states'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.canada'),
          name: 'canada',
          url: localizePath('/partners/directory/locations/canada'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.india'),
          name: 'india',
          url: localizePath('/partners/directory/locations/india'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.unitedKingdom'),
          name: 'united-kingdom',
          url: localizePath('/partners/directory/locations/united-kingdom'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.australia'),
          name: 'australia',
          url: localizePath('/partners/directory/locations/australia'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.germany'),
          name: 'germany',
          url: localizePath('/partners/directory/locations/germany'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.france'),
          name: 'france',
          url: localizePath('/partners/directory/locations/france'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.italy'),
          name: 'italy',
          url: localizePath('/partners/directory/locations/italy'),
        },
        {
          text: t('pages/partners/directory/shared:secondaryNav.viewAll'),
          name: 'view-all',
          url: localizePath('/partners/directory/locations'),
        },
      ],
    },
  ];

  return {
    sectionTitle: t(
      'pages/partners/directory/shared:secondaryNav.sectionTitle',
    ),
    sectionOverviewUrl: localizePath('/partners/directory'),
    pageTitle: '', // this will be set by the page itself
    links: links,
  };
};

export default secondaryNavFn;
